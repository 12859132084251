// Override default variables before the import
// $primary: #55C596;
// $body-bg: #EEFFF7;

@import '_variables.scss';

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

body {
    font-family: "Nunito";
}

button,
a.btn {
    border-radius: 8px !important;
}

a {
    text-decoration: none;
}

.mouse-pointer {
    cursor: pointer;
}

.custom-dropdown {

    .dropdown-toggle::after {
        display: none;
    }

    .custom-dropdown-toggle {
        padding: 0;
        box-shadow: none;
        border-color: transparent;
        background-color: transparent;

        &:hover,
        &.show,
        &:focus:not(:focus-visible) {
            outline: none;
            border-color: transparent;
            background-color: transparent;
        }
    }

    .dropdown-menu {
        border: none;
        @include box-shadow5;
        background-color: white;
    }

}

.div-disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
}

/* -------------------------------------
                 Media Query
  ----------------------------------------*/
@media (max-width: 576px) {
    .not-in-mobile {
        display: none;
    }
}