@import '../../../../_variables.scss';

.upload-pane {
    z-index: 1;
    border-radius: 8px;
    margin-bottom: 30px;
    padding: 30px !important;
    min-width: 100% !important;
    max-width: 100% !important;
    border-color: $primary !important;

    svg {
        margin-right: 20px;

        path {
            fill: $primary !important;
        }
    }

    .btn-select-file,
    .btn-select-file:hover {
        color: $primary;
        font-size: 12px;
        background-color: white;
        border: 1px solid $primary;
        margin-top: 5px;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 5px !important;
    }
}

.app-table {
    @include box-shadow5;
    padding: 20px;
    border-radius: 8px;
    background-color: white;

    tbody {
        td {
            padding: 10px 0 10px 0;
        }

        tr:hover {
            background-color: $body-bg;
        }
    }
}