@import '../../../_variables.scss';

.pdf-container {
    width: 100%;
    height: 500px;

    button:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    canvas {
        border: 5px solid white;
        border-radius: 10px;
        width: 100% !important;
        @include box-shadow2;
    }

    .react-pdf__Page__textContent,
    .react-pdf__Page__annotations {
        display: none !important;
    }
}

@media (min-width: 1400px) {
    .pdf-container {
        canvas {
            height: 600px !important;
        }
    }
}

@media (min-width: 1600px) {
    .pdf-container {
        canvas {
            height: 800px !important;
        }
    }
}

/* -------------------------------------
                 Media Query
  ----------------------------------------*/
@media (max-width: 576px) {
    .pdf-container {
        canvas {
            height: 230px !important;
        }
    }
}