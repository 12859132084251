@import '../../../_variables.scss';

.mp3-player {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: Arial, sans-serif;
    margin: 20px;

    .audio-controls {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 200px;

        button {
            border: none;
            background-color: $primary;
            @include box-shadow2;
            color: #fff;
            padding: 10px 20px;
            font-size: 16px;
            border-radius: 4px;
            cursor: pointer;
            margin: 0 10px;

            &:hover {
                scale: 1.1;
                @include transition;
                background-color: $primary;
            }
        }
    }

}