@import '../../../../_variables.scss';

.login-content {
    margin: auto;
    width: 500px;
    margin-top: 100px;
    margin-bottom: 100px;
    border-radius: 24px;
    padding: 30px 50px 70px 50px;
    @include box-shadow;

    img.logo {
        width: 150px;
    }

    .home-img {
        padding: 70px 20px 20px 20px;
        text-align: center;

        img {
            width: 270px;
            height: 250px;
        }
    }
}

/* -------------------------------------
                 Media Query
  ----------------------------------------*/
@media (max-width: 576px) {
    .login-content {
        width: 350px;
        border-radius: 16px;
        padding: 30px 50px 30px 50px;
        @include box-shadow;

        img.logo {
            width: 150px;
        }

        .home-img {
            padding: 30px 20px 20px 20px;

            img {
                width: 200px;
                height: 180px;
            }
        }
    }
}