@import '../../../_variables.scss';

.file-card-wrapper {
    background-color: white;

    &.list {
        border-radius: 8px;

        h6,
        p {
            font-size: 12px;
        }

    }

    &.grid {
        border-radius: 10px;

        h6,
        p {
            font-size: 12px;
        }

        small {
            font-size: 10px;
        }
    }

    @include box-shadow5;
}