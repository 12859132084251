.scroll-to-top-button {
    right: 20px;
    bottom: 20px;
    position: fixed;
    visibility: hidden;
    z-index: 99 !important;

    &.visible {
        visibility: visible;
    }
}