@import '../../../_variables.scss';

.sidenav {
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;

    .iconWrapper {
        height: 100%;
        width: 100px;
        padding-top: 120px;
        background-color: $primary;

        .mobile-side-menu-controller {
            cursor: pointer;
            display: none;
        }

        .iconParent {
            width: 50px;
            margin: 5px 10px;
            cursor: pointer;
            color: white;
            font-size: 18px;
            border-radius: 10px;
            padding: 5px 5px 12px 5px;
            text-align: center;

            &.active {
                color: $primary;
                @include box-shadow;
                background-color: white;
            }
        }
    }

    .menuWrapper {
        height: 100%;
        padding: 20px;
        max-width: 215px;
        margin-left: -30px;
        margin-right: 20px;
        @include box-shadow;
        border-radius: 24px;
        background-color: white;

        img.logo {
            width: 150px;
            margin: 15px 20px 35px 0;
            cursor: pointer;
        }
    }

    .list-group {
        .list-group-item {
            border: none;
            background-color: transparent;
            cursor: pointer;
        }

        a.active {
            font-weight: bold;
        }
    }

    .accordion-button:not(.collapsed) {
        background-color: transparent;
        box-shadow: none;
    }

    .accordion {
        background-color: transparent !important;

        .accordion-item {
            background-color: transparent;
        }

        .accordion-body {
            .list-group {
                border-radius: 0px;
            }

            .list-group-item {
                margin-left: 7px;
                border-left: 1px dashed $primary;

            }
        }

        .accordion-button:focus {
            box-shadow: none;
            border-color: rgba(0, 0, 0, .125);
        }

        .accordion-button {
            outline: none;
            background-color: transparent;
        }
    }
}

.main {
    margin-left: 280px;
    padding: 40px 30px 100px 30px;
}

/* -------------------------------------
               Media Query
----------------------------------------*/
@media (min-width: 768px) {}


/* -------------------------------------
                 Media Query
  ----------------------------------------*/
@media (max-width: 576px) {
    .sidenav {
        font-size: 14px;

        .iconWrapper {
            width: 70px;
            padding-top: 20px;

            &.showingMenuWrapper {
                width: 90px;
            }

            .iconParent {
                padding-top: 8px;

                &.mobile-side-menu-controller {
                    display: block;
                    width: 50px;
                    margin: 5px 10px;
                    cursor: pointer;
                    font-size: 24px;
                    padding: 5px 2px 9px 2px;
                    color: white;
                    background-color: $primary;
                    @include box-shadow;
                    margin-bottom: 100px;
                }
            }
        }

        .menuWrapper {
            padding: 20px;
            margin-left: -20px;
            margin-right: 20px;
            @include box-shadow;
            border-radius: 24px;
            background-color: white;

            &.hide {
                display: none !important;
            }
        }
    }

    .main {
        margin-left: 60px;
        padding-top: 30px;
        padding-right: 20px;
    }

}