@import '../../../_variables.scss';

.search-wrapper {
    position: relative;

    &.search-disabled {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
    }

    .search-parent {
        padding: 10px;
        border-radius: 12px;
        background-color: white;

        .search-icon {
            color: white;
            border-radius: 12px;
            text-align: center;
            font-weight: bold;
            background-color: $primary;
            padding: 10px 15px 10px 15px;
        }

        .search-input {
            padding: 10px;
            width: 100%;

            input {
                font-size: 14px;
                color: $primary;
                border: none;
                width: 100%;
                height: 100%;
            }

            input::placeholder {
                color: $primary;
                font-weight: 300;
            }

            input:focus {
                outline: none;
            }
        }
    }

    .search-result {
        position: absolute;
        top: 80px;
        left: 0;
        right: 0;
        z-index: 5;
        @include box-shadow2;

        .list-group-item {
            background-color: white;
            border-top: none;
            border-left: none;
            border-right: none;
            @include transition;

            .search-mini-icon {
                color: $primary;
            }

            &:hover {
                color: white;
                background-color: $primary;

                .search-mini-icon {
                    color: white;
                }
            }

            &:last-child {
                border: none;
            }
        }
    }

}

/* -------------------------------------
                 Media Query
  ----------------------------------------*/
@media (max-width: 576px) {
    .search-wrapper {
        font-size: 12px;

        .search-parent {
            padding: 5px;
            border-radius: 5px;

            .search-icon {
                border-radius: 5px;
                padding: 7px 8px 0px 8px;
            }

            .search-input {
                padding: 7px;
                width: 100%;

                input {
                    font-size: 12px;
                }
            }
        }

        .search-result {
            top: 60px;
            right: -80px;
        }
    }
}