@import '../../../_variables.scss';

.trashed-summary-container {
    padding: 20px 20px 0 20px;
    border: 1px solid $primary;
    border-radius: 8px;

    button {
        color: $primary;
        padding: 5px 20px;
    }
}