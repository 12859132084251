@import '../../../_variables.scss';

.alphabets-container {

    gap: 0.15rem;
    font-size: 12px;
    margin-bottom: 20px;

    small {
        border-radius: 4px;
        padding: 5px 10px;
        background: white;
        @include box-shadow5;
        border-color: $primary;
    }

    small:last-child {
        border-right-width: 0;
        border-top-right-radius: 0 !important;
    }

    small:first-child {
        border-left-width: 0;
        border-bottom-left-radius: 0 !important;
    }
}

.alphabet-parent {
    padding-top: 10px;
    margin-bottom: 10px;

    span {
        padding: 4px 10px;
        border-radius: 8px;
        text-align: center;
        font-size: 24px;
        @include box-shadow;
        background-color: white;
    }
}