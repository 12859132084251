$primary: #55C596;
$body-bg: #EEFFF7;
$black: #252525;
$enable-negative-margins: true;

/* -------------------------------------
                Box Shadow
----------------------------------------*/
@mixin box-shadow($shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.17)) {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    box-shadow: $shadow;
}

@mixin box-shadow2($shadow2: 0px 7px 19px -6px rgba(0, 0, 0, 0.25)) {
    -webkit-box-shadow: $shadow2;
    -moz-box-shadow: $shadow2;
    box-shadow2: $shadow2;
}

@mixin box-shadow3($shadow3: 0px 20px 40px 10px rgba(0, 0, 0, 0.07)) {
    -webkit-box-shadow: $shadow3;
    -moz-box-shadow: $shadow3;
    box-shadow2: $shadow3;
}

@mixin box-shadow4($shadow4: 0px 2px 0px -2px rgba(0, 0, 0, 0.17)) {
    -webkit-box-shadow: $shadow4;
    -moz-box-shadow: $shadow4;
    box-shadow: $shadow4;
}

@mixin box-shadow5($shadow5: 0px 2px 4px -2px rgba(0, 0, 0, 0.17)) {
    -webkit-box-shadow: $shadow5;
    -moz-box-shadow: $shadow5;
    box-shadow: $shadow5;
}

/* -------------------------------------
                Transitioin
----------------------------------------*/
@mixin transition($transition: all .3s) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

@mixin transform ($transform: scale(1.2)) {
    -ms-transform: $transform;
    -moz-transform: $transform;
    -webkit-transform: $transform;
    -o-transform: $transform;
    transform: $transform;
}