@import '../../../../_variables.scss';

.profile-card {
    border-radius: 16px;
    @include box-shadow5;

    img {
        border-radius: 50%;
    }

    .profile-card-child {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.profile-edit-btn {
    cursor: pointer;
    padding: 4px 10px;
    font-size: smaller;
    border-radius: 5px;
    border: 1px solid grey;
}


/* -------------------------------------
                 Media Query
  ----------------------------------------*/
@media (max-width: 576px) {
    .profile-card {
        position: relative;

        img {
            width: 50px;
            height: 50px;
        }

        .form-control-color {
            top: 10px;
            width: 35px;
            right: 10px;
            height: 35px;
            position: absolute;
            border-radius: 3px;
        }
    }
}