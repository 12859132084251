@import '../../../_variables.scss';

.app-nav {
    .profile-pic {
        margin: auto;

        img {
            width: 50px;
            height: 50px;
            margin-left: 10px;
            border-radius: 50%;
            border: 3px solid $primary;
        }
    }

    .profile-name {
        margin: auto;
        padding: 10px;

        a {
            color: $black;
        }
    }
}

/* -------------------------------------
                 Media Query
  ----------------------------------------*/
@media (max-width: 576px) {
    .app-nav {
        .profile-pic {
            img {
                width: 35px;
                height: 35px;
                margin-left: 5px;
                border: 2px solid $primary;
            }
        }

        .profile-name {
            font-size: 12px;
            padding: 5px;
        }
    }
}